import styled, { themeGet } from "@boseo/shared/styled";
import BannerImage from "../../../../../../sites/local-demo/data/images/service/banner.jpg";
export const StyledBreadCrumb = styled.div`
    color: ${themeGet("colors.primary")};
    background-color: #ebebeb;
    background-image: url(${BannerImage});
    width: 100%;
    clear: both;
    display: inline-block;
`;

export const StyledTitle = styled.h1`
    font-size: clamp(30px, 2.5vw, 44px);
    letter-spacing: -1.25px;
    margin-bottom: 12px;
    text-transform: capitalize;
    color: #fff;
    text-align: center;
    padding: 45px 15px 0px;
`;

export const StyledSubTitle = styled.p`
    text-align: center;
    padding-bottom: 45px;
    color: #ffffff;
`;
