import React from "react";
import PropTypes from "prop-types";
import { SwiperSlider, SwiperSlide, Image, Button } from "gatsby-theme-ui";
import { ShapeLayer, Shape, AnalyzeForm } from "gatsby-theme-component";
import { ItemType } from "@boseo/helper/types";
import {
    StyledSection,
    StyledSlide,
    StyledBg,
    StyledTitle,
    StyledDesc,
    StyledBtnWrap,
    StyledContent,
    StyledSliderConent,
} from "./style";

const settings = {
    slidesPerView: 1,
    autoplay: false,
    navigation: true,
};

const HeroArea = ({ data }) => (
    <StyledSection>
        {data?.items && (
            <SwiperSlider options={settings} navPosition={4} navStyle={5}>
                {data.items?.map((item) => (
                    <SwiperSlide key={item.id}>
                        <StyledSlide>
                            <StyledSliderConent
                                className="container main-slide"
                                sx={{ position: "relative", height: "100%" }}
                            >
                                <div
                                    className="row"
                                    sx={{ justifyContent: "center" }}
                                >
                                    <div className="col-lg-12">
                                        <StyledContent>
                                            <StyledTitle
                                                dangerouslySetInnerHTML={{
                                                    __html: item.title,
                                                }}
                                            />
                                            <StyledDesc
                                                dangerouslySetInnerHTML={{
                                                    __html: item.description,
                                                }}
                                            />
                                        </StyledContent>
                                        {/* {item?.buttons && (
                                            <StyledBtnWrap>
                                                {item.buttons?.map((btn) => (
                                                    <Button
                                                        key={btn.id}
                                                        {...btn}
                                                    >
                                                        {btn.content}
                                                    </Button>
                                                ))}
                                            </StyledBtnWrap>
                                        )} */}
                                    </div>
                                </div>
                                {item.images?.[0].src && (
                                    <Image
                                        className="vector-agency"
                                        src={item.images[0].src}
                                        alt={item.images[0]?.alt || item.title}
                                    />
                                )}
                                <ShapeLayer
                                    sx={{ display: ["none", null, "block"] }}
                                >
                                    <Shape layout={1} />
                                    <Shape layout={2} />
                                    <Shape layout={3} />
                                    <Shape layout={4} />
                                    <Shape layout={5} />
                                    <Shape layout={6} />
                                    <Shape layout={7} />
                                    <Shape layout={8} />
                                </ShapeLayer>
                            </StyledSliderConent>
                        </StyledSlide>
                    </SwiperSlide>
                ))}
            </SwiperSlider>
        )}
    </StyledSection>
);

HeroArea.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
    }),
};

export default HeroArea;
