/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Image } from "gatsby-theme-ui";
import { HeadingType, TextType, ImageType } from "@boseo/helper/types";
import { StyledBreadCrumb, StyledTitle, StyledSubTitle } from "./style";

const BreadCrumb = ({ data }) => (
    <StyledBreadCrumb className="Banner-section">
        <div className="Banner">
            {data?.headings?.[0] && (
                <StyledTitle as={data.headings[0]?.level}>
                    {data.headings[0].content}
                </StyledTitle>
            )}
            {data?.texts?.[0] && (
                <StyledSubTitle>{data.texts[0]?.content}</StyledSubTitle>
            )}
        </div>
    </StyledBreadCrumb>
);

BreadCrumb.propTypes = {
    data: PropTypes.shape({
        headings: PropTypes.arrayOf(PropTypes.shape(HeadingType)),
        texts: PropTypes.arrayOf(PropTypes.shape(TextType)),
        images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    }),
};

export default BreadCrumb;
