module.exports = {
    colors: {
        primary: "#1297e0", //"#eb4435",
        text: "#999191",
        heading: "#0e0e0e",
        border: "#d7d7d7",
        secondary: "#e74c3c", //"#4054b2",
        background: "#fff",
        hover: "#1297e0", //"#eb4435",
        white: "#ffffff",
        black: "#000000",
        success: "#65d066",
        info: "#17a2b8",
        warning: "#ffc107",
        danger: "#dc3545",
        light: "#f8f9fa",
        boulder: "#7a7a7a",
        shaft: "#333333",
        orange: "#ff6600",
        red: "#FF0000",
        facebook: "#425a99",
        twitter: "#67acec",
        dribbble: "#e93b88",
        pinterest: "#b51122",
        instagram: "#e04f8b",
        linkedin: "#0076b5",
        chalice: "#aba5a5",
        whisper: "#f5f6fa",
        gray50: "#fafbfc",
        gray100: "#f4f5f8",
        gray150: "#868686",
        gray200: "#e3e7ed",
        gray300: "#cdd4e0",
        gray400: "#b4bdce",
        gray500: "#97a3b9",
        gray600: "#7987a1",
        gray700: "#596882",
        gray800: "#3b4863",
        gray900: "#1c273c",
        gray950: "#f0f2f4",
        sand: "#f4f4f4",
        shark: "#212529",
        green: "#40a832",
        coffee: "#dfd6d5",
        apricot: "#f18e88",
        gallery: "#ececec",
        gallery2: "#999",
        tan: "#f6825a",
        bossanova: "#532e5b",
        bermuda: "#73dad4",
        bsun: "#ffd547",
        amethyst: "#8453cf",
        athens: "#f4f6f8",
        alabaster: "#f8f8f8",
        porcelain: "#e3e7ea",
        tundoar: "#454545",
        wild: "#f5f5f5",
        comet: "#596882",
        heather: "#c0ccda",
        silver: "#cccccc",
        foncrete: "#f3f3f3",
    },
    fontSizes: {
        body: "16px",
        h1: ["28px", "34px", "44px", "50px"],
        h2: ["24px", "30px", "40px", "44px", "44px"],
        h3: ["20px", "22px", "24px"],
        h4: ["18px", "20px"],
        h5: ["16px", "18px"],
        h6: ["16px", "16px"],
    },
    fonts: {
        body: `"Roboto", sans-serif`,
        heading: `"Poppins", sans-serif`,
    },
    fontWeights: {
        body: 400,
        heading: 600,
    },
    lineHeights: {
        body: 1.6,
        heading: 1.2,
    },
    radii: {
        sm: "3px",
        md: "5px",
        lg: "8px",
        round: "50%",
        pill: "500px",
    },
    shadows: {
        default: "0px 35px 100px 0px rgb(171 181 189 / 35%)",
        xs: "0px 15px 27px 0px rgb(171 181 189 / 35%)",
        sm: "0px 25px 20px 0px rgb(171 181 189 / 35%)",
        md: "0px 20px 80px 0px rgb(171 181 189 / 35%)",
        lg: "0px 15px 60px 0px rgb(171 181 189 / 35%)",
        xl: "0px 35px 30px 0px rgb(171 181 189 / 35%)",
        xxl: "0px 20px 50px 0px rgb(171 181 189 / 35%)",
        gray50: "0px 3px 25px 0px rgb(171 181 189 / 50%)",
        gray25: "0px 6px 25px 0px rgb(171 181 189 / 25%)",
        gray25lg: "0px 25px 60px 0px rgba(171, 181, 189, 0.25)",
        gray50lg: "0px 35px 100px 0px rgb(171 181 189 / 50%)",
        gray35: "0px 7px 30px 0px rgb(171 181 189 / 35%)",
        gray35lg: "0px 10px 40px 0px rgb(171 181 189 / 35%)",
        black: "0 0px 0px rgba(0, 0, 0, 0.05)",
        header: "0 8px 20px 0 rgba(0, 0, 0, 0.1)",
        input: "0 0 0 0.2rem rgb(1 104 250 / 25%)",
    },
    breakpoints: ["576px", "768px", "992px", "1200px", "1400px"],
    transition: "all 0.4s ease 0s",
    flex: {
        hrCenter: {
            display: "flex",
            justifyContent: "center",
        },
    },
};
