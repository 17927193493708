/** @jsx jsx */
import { jsx } from "theme-ui";
import { useState, useEffect } from "react";
import { Blog06 } from "gatsby-theme-component";

const Bloglists = () => {
    const url = "http://localhost/wordpress/wp-json/rapid/v1/blogs";
    const useFetch = () => {
        const [loading, setLoading] = useState(true);
        const [data, setData] = useState([]);

        const paginate = (followers) => {
            const itemsPerPage = 3;
            const numberOfPages = Math.ceil(followers.length / itemsPerPage);

            const newFollowers = Array.from(
                { length: numberOfPages },
                (_, index) => {
                    const start = index * itemsPerPage;
                    return followers.slice(start, start + itemsPerPage);
                }
            );

            return newFollowers;
        };

        const getProducts = async () => {
            const response = await fetch(url);
            const data = await response.json();
            setData(paginate(data));
            setLoading(false);
            console.log(data);
        };

        useEffect(() => {
            getProducts();
        }, []);
        return { loading, data };
    };
    const { loading, data } = useFetch();
    const [page, setPage] = useState(0);
    const [followers, setFollowers] = useState([]);

    useEffect(() => {
        if (loading) return;
        setFollowers(data[page]);
    }, [loading, page]);

    const nextPage = () => {
        setPage((oldPage) => {
            let nextPage = oldPage + 1;
            if (nextPage > data.length - 1) {
                nextPage = 0;
            }
            return nextPage;
        });
    };
    const prevPage = () => {
        setPage((oldPage) => {
            let prevPage = oldPage - 1;
            if (prevPage < 0) {
                prevPage = data.length - 1;
            }
            return prevPage;
        });
    };

    const handlePage = (index) => {
        setPage(index);
    };
    return (
        <div className="container">
            <div className="row gx-45">
                {/* {blog &&
                    blog.map((post) => (
                        <div
                            key={post.id}
                            className="col-lg-4 col-md-6"
                            sx={{ mb: ["47px", null, "52px"] }}
                        >
                            <Blog06
                                title={post.post_title}
                                author={post.author}
                                date={post.dates}
                                slug={post.post_name}
                                image={post.thumbnail}
                                format={post.format}
                                video_link={post.video_link}
                                gallery_images={post.gallery_images}
                            />
                        </div>
                    ))} */}
                {followers &&
                    followers.map((post, i) => (
                        <div
                            key={i}
                            className="col-lg-4 col-md-6"
                            sx={{ mb: ["47px", null, "52px"] }}
                        >
                            <Blog06
                                title={post.post_title}
                                author={post.author}
                                date={post.dates}
                                slug={post.post_name}
                                image={post.thumbnail}
                                format={post.format}
                                video_link={post.video_link}
                                gallery_images={post.gallery_images}
                            />
                        </div>
                    ))}
                {!loading && (
                    <div className="btn-container">
                        <ul>
                            <li className="prev-btn" onClick={prevPage}>
                                prev
                            </li>
                            {data.map((item, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={`page-btn ${
                                            index === page ? "active-btn" : null
                                        }`}
                                        onClick={() => handlePage(index)}
                                    >
                                        {index + 1}
                                    </li>
                                );
                            })}
                            <li className="next-btn" onClick={nextPage}>
                                next
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};
export default Bloglists;
