/** @jsx jsx */
import { jsx } from "theme-ui";
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { ImageType } from "@boseo/helper/types";
import {
    Image,
    Anchor,
    ModalVideo,
    SwiperSlider,
    SwiperSlide,
} from "gatsby-theme-ui";
import { StyledThumb, StyledVideoBtn } from "./style";

const sliderOptions = {
    slidesPerView: 1,
    autoplay: true,
};
const BlogMediaAPI = ({
    className,
    format,
    image,
    title,
    slug,
    video_link,
    gallery_images,
    layout,
    isDetails,
    showVideoBtn,
    sx,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    let video_arr;
    let video_id;
    let video_channel;
    if (video_link) {
        video_arr = video_link.split("=", -1);
        // eslint-disable-next-line prefer-destructuring
        video_id = video_arr[1];
        // eslint-disable-next-line prefer-destructuring
        video_channel = video_link.split(".")[1];
    }
    const onClickHandler = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <div className={cn("blog-media", className)} sx={sx}>
            {format === "image" && image && (
                <StyledThumb
                    className="blog-thumb"
                    layout={!isDetails && layout}
                >
                    <Image src={image} alt="blog image" />
                    {!isDetails && (
                        <Anchor className="link-overlay" path={slug}>
                            <span className="sr-only">{title}</span>
                        </Anchor>
                    )}
                </StyledThumb>
            )}
            {format === "video" && (
                <StyledThumb className="blog-video" layout={layout}>
                    <Image src={image.src} alt={image?.alt || title} />
                    {showVideoBtn && (
                        <Fragment>
                            <StyledVideoBtn onClick={onClickHandler}>
                                <i className="fa fa-play" />
                            </StyledVideoBtn>

                            <ModalVideo
                                channel={video_channel}
                                isOpen={isOpen}
                                videoId={video_id}
                                onClose={onClickHandler}
                            />
                        </Fragment>
                    )}
                </StyledThumb>
            )}
            {format === "gallery" && gallery_images && (
                <SwiperSlider options={sliderOptions}>
                    {gallery_images.map((img) => (
                        <SwiperSlide key={img.src?.absolutePath}>
                            <StyledThumb
                                className="blog-thumb"
                                layout={!isDetails && layout}
                            >
                                <Image src={img.src} alt={img?.alt || title} />
                                {!isDetails && (
                                    <Anchor
                                        className="link-overlay"
                                        path={slug}
                                    >
                                        <span className="sr-only">{title}</span>
                                    </Anchor>
                                )}
                            </StyledThumb>
                        </SwiperSlide>
                    ))}
                </SwiperSlider>
            )}
        </div>
    );
};

BlogMediaAPI.propTypes = {
    format: PropTypes.oneOf(["image", "gallery", "video"]),
    image: PropTypes.string,
    video_link: PropTypes.string,
    gallery_images: PropTypes.arrayOf(PropTypes.shape(ImageType)),
    title: PropTypes.string,
    slug: PropTypes.string,
    className: PropTypes.string,
    layout: PropTypes.oneOf([1, 2, 3]),
    isDetails: PropTypes.bool,
    showVideoBtn: PropTypes.bool,
    sx: PropTypes.shape({}),
};

BlogMediaAPI.defaultProps = {
    layout: 1,
    isDetails: false,
    showVideoBtn: true,
};

export default BlogMediaAPI;
