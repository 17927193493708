import React from "react";
import PropTypes from "prop-types";
import { GoogleMap } from "gatsby-theme-ui";

const GoogleMapArea = ({ data }) => (
    <GoogleMap lat={34.51601} lng={-84.905818} />
);
// <GoogleMap lat={data.lat} lng={data.lng} />
GoogleMapArea.propTypes = {
    data: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
    }),
};

export default GoogleMapArea;
