import styled, { device } from "@boseo/shared/styled";

export const StyledSection = styled.section`
    position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    background: #f0f2f4;
    ${device.small} {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    ${device.medium} {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    ${device.large} {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    ${device.xlarge} {
        padding-top: 80px;
        padding-bottom: 80px;
    }
`;

export const StyledImage = styled.figure``;
