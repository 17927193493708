/** @jsx jsx */
import { jsx } from "theme-ui";
//import { useState, useEffect } from "react";
import { BlogDetails02 } from "gatsby-theme-container";
//import { Blog06 } from "gatsby-theme-component";

const BlogDetail = (data) => {
    const blog = data.data;
    return (
        <div className="container">
            <div className="row gx-45">
                {blog &&
                    blog.map((post, i) => (
                        <BlogDetails02 data={post} key={i} />
                    ))}
            </div>
        </div>
    );
};
export default BlogDetail;
