import styled, { device } from "@boseo/shared/styled";

export const StyledSection = styled.section`
    padding-top: 70px;
    padding-bottom: 0px;
    ${device.medium} {
        padding-bottom: 0px;
        padding-top: 70px;
    }
    ${device.large} {
        padding-bottom: 0px;
        padding-top: 80px;
    }
    ${device.xlarge} {
        padding-bottom: 0px;
        padding-top: 80px;
    }
`;
